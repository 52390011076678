<template>
  <div>
    <v-row class="d-flex justify-space-around">
      <v-card
        width="45%"
        tile
      >
        <v-progress-linear
          v-if="loadingCustomer"
          indeterminate
          color="primary"
        />
        <v-list-item-content
          v-if="!loadingCustomer"
          class="px-3 py-2"
        >
          <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-1" /> {{ $t('name') }}: <span class="float-right mt-2">{{ detailedUser.FullName }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-1" /> {{ $t('email') }}: <span class="float-right mt-2">{{ detailedUser.Email }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-phone px-2 py-1" /> {{ $t('phone-number') }}: <span class="float-right mt-2">{{ detailedUser.Phone }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-cloud px-2 py-1" /> {{ $t('status') }}: <span class="float-right mt-2">{{ getVerifierShortDescription(userStatuses, detailedUser.Status) }}</span></v-list-item-subtitle>
        </v-list-item-content>
      </v-card>
      <!-- <v-card
        width="45%"
        tile
      >
        <v-progress-linear
          v-if="loadingBranchOffice"
          indeterminate
          color="primary"
        />
        <v-list-item-content
          v-if="!loadingBranchOffice"
          class="px-3 py-2"
        >
          <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-1" /> {{ $t('branchOfficeName') }}: <span class="float-right mt-2">{{ clickedBranchOffice.branchOfficeName }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-account-check px-2 py-1" /> {{ $t('contactPerson') }}: <span class="float-right mt-2">{{ clickedBranchOffice.contactPerson }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-1" /> {{ $t('contactEmail') }}: <span class="float-right mt-2">{{ clickedBranchOffice.contactEmail }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-phone px-2 py-1" /> {{ $t('contactPhone') }}: <span class="float-right mt-2">{{ clickedBranchOffice.contactPhone }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-cloud px-2 py-1" /> {{ $t('status') }}: <span class="float-right mt-2">{{ clickedBranchOffice.status === 1 ? 'Active' : 'Inactive' }}</span></v-list-item-subtitle>
        </v-list-item-content>
      </v-card> -->
      <v-card
        width="45%"
        tile
      >
        <v-card-title>
          {{ $t('orders') }}
        </v-card-title>
        <v-data-table
          :loading="loadingOrders"
          :headers="headers"
          :items="filteredOrders"
          :items-per-page="filteredOrders.length"
          dense
          sort-by="Id"
          item-key="Id"
          fluid
          fixed-header
          style="cursor:pointer"
          class="elevation-1"
        >
          <template
            v-if="userData.roleData.roleName === 'BRANCH_ADMIN' || userData.roleData.roleName === 'BRANCH_STAFF'"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  @click="deleteOrder(item)"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t('deleteOrder') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
  </div>
</template>
<script>
  import { mapGetters, mapState } from 'vuex'
  export default {
    props: {
      item: {
        type: Object,
        default: function () {
          return {}
        },
      },
      deliveryOrderCreated: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      loadingOrders: true,
      loadingCustomer: false,
      loadingBranchOffice: false,
      clickedCustomer: {
        customerName: '',
        contactEmail: '',
        contactPerson: '',
        contactPhone: '',
        status: '',
      },
      clickedBranchOffice: {
        branchOfficeName: '',
        contactEmail: '',
        contactPerson: '',
        contactPhone: '',
        status: '',
      },
      orders: [],
    }),
    computed: {
      ...mapState({
        selectedCustomer: (state) => state.customer.selectedCustomer,
        selectedBranchOffice: (state) => state.branchOffice.selectedBranchOffice,
        selectedDelivery: (state) => state.deliveries.detailedDelivery,
        detailedUser: (state) => state.user.selectedUser,
        userStatuses: (entytiStatuses) => entytiStatuses.verifier.userStatuses,
        userData: (state) => state.authentication.userData,
      }),
      ...mapGetters({
        getVerifierCustomerId: 'verifier/getVerifierCustomerId',
        getVerifierId: 'verifier/getVerifierId',
        getVerifierLongDescription: 'verifier/getVerifierLongDescription',
        getVerifierLowValue: 'verifier/getVerifierLowValue',
        getVerifierRvDomain: 'verifier/getVerifierRvDomain',
        getVerifierShortDescription: 'verifier/getVerifierShortDescription',
        getObjectVerifierById: 'verifier/getObjectVerifierById',
      }),
      headers () {
        let result = []
        if (this.userData.roleData.roleName === 'BRANCH_ADMIN' || this.userData.roleData.roleName === 'BRANCH_STAFF') {
          result = [
            { text: this.$t('consumerName'), value: 'ConsumerName', align: 'start' },
            { text: this.$t('orderCode'), value: 'OrderCode' },
            { text: this.$t('status'), value: 'Status' },
            { text: this.$t('actions'), value: 'actions', sortable: false, align: 'end' },
          ]
        } else {
          result = [
            { text: this.$t('consumerName'), value: 'ConsumerName', align: 'start' },
            { text: this.$t('orderCode'), value: 'OrderCode' },
            { text: this.$t('status'), value: 'Status' },
          ]
        }
        return result
      },
      filteredOrders () {
        return this.orders
      },
    },
    watch: {
      deliveryOrderCreated (val) {
        if (val) {
          this.getSelectedDelivery()
        }
      },
      item (val) {
        this.init()
      },
    },
    async beforeMount () {
      await this.init()
    },
    methods: {
      async init () {
        await this.getSelectedDriver()
        // await this.getSelectedBranchOffice()
        await this.getSelectedDelivery()
      },
      async deleteOrder (item) {
        await this.$store.dispatch('deliveryOrder/deleteDeliveryOrder', item.Id).then(() => {
          this.orders = []
          this.getSelectedDelivery().then(() => {
            const body = {
              OrderId: item.OrderId,
              statusValueToSet: 3,
            }
            this.$store.dispatch('order/setOrderStatusById', body)
              .then(() => {
                this.$emit('refresh-order-list')
              })
          })
        })
      },
      async getSelectedDriver () {
        try {
          this.loadingCustomer = true
          await this.$store.dispatch('user/getUserById', this.item.DeliveryDriverId)
            .then(() => {
              this.loadingCustomer = false
            })
        } catch (err) {
        }
      },
      async getSelectedBranchOffice () {
        try {
          this.loadingBranchOffice = true
          await this.$store.dispatch('branchOffice/getBranchOfficesById', this.item.BranchOfficeId)
            .then(() => {
              Object.assign(this.clickedBranchOffice, this.selectedBranchOffice.branchOfficeDB)
              this.loadingBranchOffice = false
            })
        } catch (err) {
        }
      },
      async getSelectedDelivery () {
        this.orders = []
        try {
          this.loadingBranchOffice = true
          await this.$store.dispatch('deliveries/getDeliveryById', this.item.Id)
            .then(() => {
              this.selectedDelivery.ordersForThisDelivery.forEach(deliveryOrder => {
                const newOrder = {
                  OrderId: deliveryOrder.orderId,
                  ConsumerName: deliveryOrder.consumerData.consumerName,
                  OrderCode: deliveryOrder.orderData.orderCode,
                  Status: this.getStatus(deliveryOrder.status),
                  Id: deliveryOrder.id,
                }
                this.orders.push(newOrder)
              })
            })
        } catch (err) {
        }
        this.loadingOrders = false
      },
      getStatus (statusId) {
        if (statusId === 1) {
          return 'Active'
        } else return 'Inactive'
      },
    },
  }
</script>
