<template>
  <v-container
    fluid
    tag="section"
    class="mt-1"
  >
    <v-card>
      <v-card-title class="card-title">
        <h4>{{ $t('deliveries') }}</h4>
        <v-spacer />
        <v-btn
          v-if="userData.roleData.roleName === 'BRANCH_ADMIN' || userData.roleData.roleName === 'BRANCH_STAFF'"
          color="primary"
          rounded
          small
          dark
          @click="addDelivery"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="mainHeaders"
        :footer-props="footerProps"
        :items="filteredDeliveries"
        :items-per-page="filteredDeliveries.length"
        :single-expand="true"
        :expanded.sync="expanded"
        :search="search"
        dense
        sort-desc
        sort-by="Id"
        item-key="Id"
        fluid
        fixed-header
        style="cursor:pointer"
        class="elevation-1"
        @click:row="expand"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <v-spacer />
            <v-spacer />
            <v-spacer />
            <v-row>
              <v-col
                cols="12"
                lg="3"
                md="3"
                sm="3"
                offset="8"
              >
                <v-select
                  v-if="!loading"
                  v-model="selectedStatus"
                  :loading="loading"
                  :items="deliveryStatuses"
                  item-text="ShortDescription"
                  item-value="LowValue"
                  dense
                  multiple
                  placeholder="All"
                  class="mt-6"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            />
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <delivery-details-card
              :item="item"
              :delivery-order-created="deliveryOrderCreated"
              @refresh-order-list="refreshOrder"
            />
          </td>
        </template>
      </v-data-table>
    </v-card>
    <div
      v-if="userData.roleData.roleName === 'BRANCH_ADMIN' || userData.roleData.roleName === 'BRANCH_STAFF'"
    >
      <v-row>
        <v-col
          cols="6"
          class="pr-6"
        >
          <v-card
            class="px-2 d-flex flex-column"
            outlined
            tile
            height="100%"
          >
            <v-card-title class="pt-6">
              {{ $t('drivers') }}
            </v-card-title>
            <v-row
              no-gutters
            >
              <v-col>
                <v-card
                  height="400px"
                >
                  <v-simple-table
                    height="400px"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t('Id') }}
                          </th>
                          <th class="text-left">
                            {{ $t('name') }}
                          </th>
                          <th class="text-left">
                            {{ $t('status') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="driver in deliveryTransporters"
                          :key="driver.Id"
                        >
                          <td>{{ driver.Id }}</td>
                          <td>{{ driver.FullName }}</td>
                          <td>{{ driver.StatusForDisplay }}</td>
                          <td
                            v-if="flag"
                          >
                            <v-radio-group v-model="clickedDriver">
                              <v-radio
                                :key="driver.Id"
                                :value="driver.Id"
                                @change="editDeliveryDriver(driver)"
                              />
                            </v-radio-group>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          cols="6"
        >
          <v-card
            class="px-2 d-flex flex-column"
            outlined
            tile
            height="100%"
          >
            <v-card-title class="pt-6">
              {{ $t('orders') }}
            </v-card-title>
            <v-row>
              <v-col
                cols="12"
              >
                <v-card
                  height="400px"
                >
                  <v-simple-table
                    height="400px"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t('consumerName') }}
                          </th>
                          <th class="text-left">
                            {{ $t('orderCode') }}
                          </th>
                          <th class="text-left">
                            {{ $t('status') }}
                          </th>
                          <th
                            v-if="flag"
                            class="text-left"
                          >
                            {{ $t('select') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="order in orders"
                          :key="order.Id"
                        >
                          <td>{{ order.consumerName }}</td>
                          <td>{{ order.OrderCode }}</td>
                          <td>{{ order.StatusForDisplay }}</td>
                          <td
                            v-if="flag"
                          >
                            <v-checkbox
                              v-model="checked"
                              :value="order.Id"
                              @change="chooseOrders(order.Id, order)"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
            <v-dialog
              v-model="popupdialog"
              width="500"
            >
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Error Dialog
                </v-card-title>

                <v-card-text>
                  Please, select a delivery to attach orders
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="addDeliveryDialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="pa-3 color: primary"
          dense
        >
          <h5 class="white--text">
            {{ $t('createDelivery') }}
          </h5>
          <v-spacer />

          <v-icon
            color="white"
            aria-label="Close"
            @click="closeDeliveryDialog"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="currentDriver"
            :headers="deliveryHeaders"
            hide-default-footer
            :items="deliveryTransporters"
            :single-select="true"
            item-value="Name"
            item-key="Id"
            show-select
            class="elevation-1"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            class="primary"
            width="110px"
            @click="createDelivery()"
          >
            {{ $t('processOrder') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import { mapGetters, mapState } from 'vuex'
  import DeliveryDetailsCard from '../Cards/DeliveryDetailsCard.vue'
  export default {
    components: {
      DeliveryDetailsCard,
      // DeliveryTransporters,
    },
    data: () => ({
      search: '',
      popupdialog: false,
      addDeliveryDialog: false,
      currentDriver: [],
      flag: false,
      refreshList: false,
      filterDeliveryStatus: 'DeliveryCreated',
      statuses: [],
      loadComponent: false,
      selectedStatus: [],
      loading: true,
      deliveries: [],
      footerProps: { 'items-per-page-options': [-1, 5, 10, 15] },
      expanded: [],
      deliveryTransporters: {},
      selectedDelivery: {},
      orders: {},
      checked: false,
      clickedDriver: 1,
      deliveryOrderCreated: false,
    }),
    computed: {
      ...mapState({
        userData: (state) => state.authentication.userData,
        deliveriesFromRepo: (state) => state.deliveries.deliveries,
        detailedDelivery: (state) => state.deliveries.detailedDelivery,
        deliveryStatuses: (entytiStatuses) => entytiStatuses.verifier.deliveryStatuses,
        usersFromRepo: (state) => state.user.users,
        ordersFromRepo: (state) => state.order.orders,
        orderStatuses: (entytiStatuses) => entytiStatuses.verifier.orderStatuses,
        userStatuses: (entytiStatuses) => entytiStatuses.verifier.userStatuses,
      }),
      ...mapGetters({
        getVerifierCustomerId: 'verifier/getVerifierCustomerId',
        getVerifierId: 'verifier/getVerifierId',
        getVerifierLongDescription: 'verifier/getVerifierLongDescription',
        getVerifierLowValue: 'verifier/getVerifierLowValue',
        getVerifierRvDomain: 'verifier/getVerifierRvDomain',
        getVerifierShortDescription: 'verifier/getVerifierShortDescription',
        getObjectVerifierById: 'verifier/getObjectVerifierById',
      }),
      mainHeaders () {
        let result = []
        result = [
          { text: this.$t('Id'), value: 'Id', align: 'start' },
          { text: this.$t('customerName'), value: 'CustomerName' },
          { text: this.$t('driverName'), value: 'DeliveryDriverFullname' },
          { text: this.$t('branchOfficeName'), value: 'BranchOfficeName' },
          { text: this.$t('status'), value: 'StatusForDisplay' },
        ]
        return result
      },
      deliveryHeaders () {
        return [
          { text: this.$t('status'), value: 'FullName' },
        ]
      },
      filteredDeliveries () {
        let deliveries = []
        this.deliveries.forEach(() => {
          if (this.selectedStatus.length === 0) {
            deliveries = this.deliveries
          } else {
            deliveries = this.deliveries.filter(delivery => this.selectedStatus.includes(delivery.Status))
          }
        })
        return deliveries
      },
      role () {
        return this.userData.roleData.roleName !== 'CUSTOMER_ADMIN' && this.userData.roleData.roleName !== 'REGIONAL_ADMIN'
      },
    },
    async mounted () {
      await this.getDeliveries()
      await this.getDrivers()
      await this.getOrders()
      this.loadComponent = true
    },
    methods: {
      createDelivery () {
        const deliveryBody = {
          deliveryDriverId: this.currentDriver[0].Id,
          status: 1,
        }
        if (this.userData.roleData.roleName === 'BRANCH_ADMIN' || this.userData.roleData.roleName === 'BRANCH_STAFF') {
          deliveryBody.customerId = this.userData.customerId
          deliveryBody.branchOfficeId = this.userData.branchOfficeId
          this.$store.dispatch('deliveries/createDelivery', deliveryBody)
            .then(() => {
              this.closeDeliveryDialog()
            })
        } else {
          this.closeDeliveryDialog() // TODO: Handle logic for regional admin and customer admin
          // deliveryBody.customerId = this.userData.id
          // deliveryBody.branchOfficeId = this.branchSelected
        }
      },
      async getDrivers () {
        await this.$store.dispatch('user/getUser')
          .then(() => {
            this.deliveryTransporters = this.usersFromRepo.filter(user => user.RoleName === 'DELIVERY_TRANSPORTER')
            this.deliveryTransporters.forEach(c => {
              c.StatusForDisplay = this.getVerifierShortDescription(this.userStatuses, c.Status)
            })
          })
      },
      async getOrders () {
        await this.$store.dispatch('order/getOrders')
          .then(() => {
            this.orders = this.ordersFromRepo.map(o => {
              return {
                Id: o.Id,
                consumerName: o.ConsumerData.consumerName,
                OrderCode: o.OrderCode,
                Status: o.Status,
                StatusForDisplay: this.getVerifierLongDescription(this.orderStatuses, o.Status),
                ConsumerData: o.ConsumerData,
                ConsumerId: o.ConsumerId,
                ConsumerUserId: o.ConsumerUserId,
                ContractId: o.ContractId,
                CustomerId: o.CustomerId,
                DealerId: o.DealerId,
                OrderDate: o.OrderDate,
                OrderTimestamp: o.OrderTimestamp,
                TotalOrderPrice: o.TotalOrderPrice,
              }
            }).filter(order => order.Status === 3)
          })
      },
      refreshOrder () {
        this.getOrders()
      },
      async editDeliveryDriver (item) {
        if (this.userData.roleData.roleName === 'BRANCH_ADMIN' || this.userData.roleData.roleName === 'BRANCH_STAFF') {
          const body = {
            id: this.selectedDelivery.Id,
            customerId: this.userData.customerId,
            branchOfficeId: this.userData.branchOfficeId,
            deliveryDriverId: item.Id,
            status: 1,
          }
          await this.$store.dispatch('deliveries/updateDelivery', body)
            .then(() => {
              this.getDeliveries()
            })
        } else {
          this.getDeliveries()
        }
      },
      async getDeliveries () {
        this.loading = true
        await this.$store.dispatch('deliveries/getDelivery')
          .then(() => {
            this.deliveries = this.deliveriesFromRepo.map(d => {
              return {
                Id: d.Id,
                CustomerId: d.CustomerId,
                CustomerName: d.CustomerName,
                DeliveryDriverId: d.DeliveryDriverId,
                DeliveryDriverFullname: d.DeliveryDriverFullname,
                BranchOfficeName: d.BranchOfficeName,
                BranchOfficeId: d.BranchOfficeId,
                StatusForDisplay: this.getVerifierLongDescription(this.deliveryStatuses, d.Status),
                Status: d.Status,
              }
            })
            this.loading = false
          })
      },
      async expand (item) {
        if (item === this.expanded[0]) {
          this.expanded = []
          this.selectedDelivery = {}
          this.flag = false
        } else if (item !== this.expanded[0] && item.Status !== 1) {
          this.expanded = [item]
          this.selectedDelivery = item
          this.flag = false
        } else {
          this.expanded = [item]
          this.selectedDelivery = item
          this.flag = true
          this.clickedDriver = item.DeliveryDriverId
        }
      },
      chooseOrders (id, order) {
        if (this.userData.roleData.roleName === 'BRANCH_ADMIN' || this.userData.roleData.roleName === 'BRANCH_STAFF') {
          this.deliveryOrderCreated = false
          if (this.selectedDelivery.Id === undefined) {
            this.popupdialog = true
            return
          }
          const body = {
            DeliveryId: this.selectedDelivery.Id,
            OrderId: id,
            CustomerId: this.userData.customerId,
            BranchOfficeId: this.userData.branchOfficeId,
            ConsumerId: order.ConsumerId,
            PickupAddressId: order.ConsumerData.shippingAddressId,
            DropOffAddressId: this.userData.branchOfficeData.addressId,
            Status: 1,
          }
          this.$store.dispatch('deliveryOrder/createDeliveryOrder', body)
            .then(() => {
              const body = {
                OrderId: order.Id,
                statusValueToSet: 5,
              }
              this.$store.dispatch('order/setOrderStatusById', body)
                .then(() => {
                  this.getOrders()
                  this.deliveryOrderCreated = true
                  this.checked = false
                })
            })
        } else {
          this.getOrders() // TODO: Handle for regional admin and customer admin
        }
      },
      addDelivery () {
        this.addDeliveryDialog = true
      },
      closeDeliveryDialog () {
        this.addDeliveryDialog = false
        this.getDeliveries()
      },
    },
  }
</script>
